export default [
  {
    header: 'PLATAFORMA',
    resource: 'rt-plataforma',
  },
  {
    title: 'Tablero de Indicadores',
    typeIcon: 'awesome',
    route: 'rt-plataforma-info-tablero',
    icon: 'fa-solid fa-desktop',
    resource: 'rt-plataforma-info-tablero',
  },
  {
    title: 'Transacciones',
    icon: 'fa-solid fa-dollar-sign',
    typeIcon: 'awesome',
    route: 'rt-plataforma-transaccion',
    resource: 'rt-plataforma-transaccion',
  },
  {
    title: 'Noticias',
    icon: 'fa-solid fa-newspaper',
    typeIcon: 'awesome',
    route: 'rt-plataforma-noticia',
    resource: 'rt-plataforma-noticia',
  },
  {
    title: 'Info Turística',
    icon: 'fa-solid fa-suitcase-rolling',
    typeIcon: 'awesome',
    children: [
      {
        title: 'Atractivos',
        icon: 'fa-solid fa-umbrella-beach',
        typeIcon: 'awesome',
        route: 'rt-plataforma-info-atractivo',
        resource: 'rt-plataforma-info-atractivo',
      },
      {
        title: 'Empresas',
        icon: 'fa-solid fa-bell-concierge',
        typeIcon: 'awesome',
        route: 'rt-plataforma-info-empresa',
        resource: 'rt-plataforma-info-empresa',
      },
      {
        title: 'Festividades',
        icon: 'fa-solid fa-calendar-days',
        typeIcon: 'awesome',
        route: 'rt-plataforma-info-festividades',
        resource: 'rt-plataforma-info-festividades',
      },
      {
        title: 'Rutas',
        icon: 'fa-solid fa-route',
        typeIcon: 'awesome',
        route: 'rt-plataforma-info-ruta',
        resource: 'rt-plataforma-info-ruta',
      },
      {
        title: 'Destinos',
        icon: 'fa-solid fa-location-dot',
        typeIcon: 'awesome',
        route: 'rt-plataforma-info-destino',
        resource: 'rt-plataforma-info-destino',
      },
      {
        title: 'Tipos de Empresas',
        icon: 'fa-solid fa-building-circle-check',
        typeIcon: 'awesome',
        route: 'rt-plataforma-info-tipo-empresa',
        resource: 'rt-plataforma-info-tipo-empresa',
      },
    ],
  },
  {
    title: 'Moderar Contenido',
    typeIcon: 'awesome',
    route: 'rt-plataforma-contenido',
    icon: 'fa-solid fa-desktop',
    resource: 'rt-plataforma-contenido',
  },
  {
    title: 'Diseno',
    typeIcon: 'awesome',
    route: 'rt-plataforma-diseno',
    icon: 'fa-solid fa-object-group',
    resource: 'rt-plataforma-diseno',
  },
  {
    title: 'Accesos',
    icon: 'fa-solid fa-shield-halved',
    typeIcon: 'awesome',
    children: [
      {
        title: 'Empresas',
        icon: 'fa-solid fa-id-card-clip',
        typeIcon: 'awesome',
        route: 'rt-plataforma-access-empresa',
        resource: 'rt-plataforma-access-empresa',
      },
      {
        title: 'Turistas',
        icon: 'fa-solid fa-user-shield',
        typeIcon: 'awesome',
        route: 'rt-plataforma-access-turista',
        resource: 'rt-plataforma-access-turista',
      },
    ],
  },
  {
    header: 'ADMINISTRACIÓN',
    resource: 'rt-administracion',
  },
  {
    title: 'Accesos Recurso',
    route: 'rt-administracion-accesorecurso',
    icon: 'CornerUpRightIcon',
    resource: 'rt-administracion-accesorecurso',
  },
  {
    title: 'Actividades Recurso',
    route: 'rt-administracion-actividadrecurso',
    icon: 'CheckSquareIcon',
    resource: 'rt-administracion-actividadrecurso',
  },
  {
    title: 'Clases Hospedaje',
    route: 'rt-administracion-clase-hospedaje',
    icon: 'StarIcon',
    resource: 'rt-administracion-clase-hospedaje',
  },
  {
    title: 'Clasificacion',
    route: 'rt-administracion-clasificacion',
    icon: 'ListIcon',
    resource: 'rt-administracion-clasificacion',
  },
  {
    title: 'Infraestructura Recurso',
    route: 'rt-administracion-infrarecurso',
    icon: 'TrelloIcon',
    resource: 'rt-administracion-infrarecurso',
  },
  {
    title: 'Modalidad Turismo',
    route: 'rt-administracion-modalidad-turismo',
    icon: 'TrendingUpIcon',
    resource: 'rt-administracion-modalidad-turismo',
  },
  {
    title: 'Servicios Recursos',
    route: 'rt-administracion-serviciorecurso',
    icon: 'BookmarkIcon',
    resource: 'rt-administracion-serviciorecurso',
  },
  {
    header: 'Seguridad',
    resource: 'rt-seguridad',
  },
  {
    title: 'Usuarios',
    route: 'rt-seguridad-usuarios',
    icon: 'UsersIcon',
    resource: 'rt-seguridad-usuarios',
  },
  {
    title: 'Perfiles',
    route: 'rt-seguridad-perfiles',
    icon: 'AirplayIcon',
    resource: 'rt-seguridad-perfiles',
  },
  {
    title: 'Parámetros',
    route: 'rt-seguridad-parametros',
    icon: 'KeyIcon',
    resource: 'rt-seguridad-parametros',
  },
  {
    title: 'Log Operacion',
    route: 'rt-seguridad-log-operacion',
    icon: 'SearchIcon',
    resource: 'rt-seguridad-log-operacion',
  },
]
